// this page is now obselete 

import React, { useEffect } from "react";
import { navigate } from "gatsby";

const RedirectPage = () => {
  useEffect(() => {
    // Redirects to /target-page when this component mounts
    navigate("/privacy");
  }, []);

  return <p>Redirecting...</p>; // Optional, renders while the redirect happens
};

export default RedirectPage;
